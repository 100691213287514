<mat-toolbar class="header">
  <div class="logo-div">
    <img
      routerLink="/home"
      class="logo"
      src="../../../assets/img/malspirale_logo.png"
      alt="Malspirale Logo"
    />
  </div>
  <div class="nav-buttons">
    <button mat-button color="accent" routerLink="/home">
      <mat-icon>home</mat-icon>
      Home
    </button>
    <button mat-button color="accent" routerLink="/philosophy">
      <mat-icon>history_edu</mat-icon>
      Philosophie
    </button>
    <button mat-button color="accent" routerLink="/colors">
      <mat-icon>color_lens</mat-icon>
      Farben
    </button>
    <button mat-button color="accent" [matMenuTriggerFor]="galleryMenu">
      <mat-icon>image</mat-icon>
      Galerie
    </button>
    <mat-menu class="menu" color="primary" #galleryMenu="matMenu">
      <button
        class="menu-item"
        mat-menu-item
        color="primary"
        routerLink="/gallery"
      >
        Galerie
      </button>
      <button
        class="menu-item"
        mat-menu-item
        color="primary"
        routerLink="/artist"
      >
        Artist
      </button>
    </mat-menu>
    <button mat-button color="accent" routerLink="/courses">
      <mat-icon>calendar_month</mat-icon>
      Kurse
    </button>
    <button mat-button color="accent" routerLink="/contact">
      <mat-icon>contact_support</mat-icon>
      Kontakt
    </button>
  </div>
  <button
    color="primary"
    mat-mini-fab
    type="button"
    class="burger-menu"
    (click)="openDialog()"
  >
    <mat-icon>menu</mat-icon>
  </button>
</mat-toolbar>
