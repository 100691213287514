<mat-card class="overlay-menu">
  <button mat-raised-button color="primary" routerLink="/home">
    <mat-icon>home</mat-icon>
    Home
  </button>

  <button mat-raised-button color="primary" routerLink="/philosophy">
    <mat-icon>history_edu</mat-icon>
    Philosophie
  </button>
  <button mat-raised-button color="primary" routerLink="/colors">
    <mat-icon>color_lens</mat-icon>
    Farben
  </button>
  <button mat-raised-button color="primary" routerLink="/gallery">
    <mat-icon>image</mat-icon>
    Galerie
  </button>
  <button mat-raised-button color="primary" routerLink="/artist">
    <mat-icon>person</mat-icon>
    Artist
  </button>
  <button mat-raised-button color="primary" routerLink="/courses">
    <mat-icon>calendar_month</mat-icon>
    Kurse
  </button>
  <button mat-raised-button color="primary" routerLink="/contact">
    <mat-icon>contact_support</mat-icon>
    Kontakt
  </button>
</mat-card>

