import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatCard } from '@angular/material/card';

@Component({
  standalone: true,
  selector: 'ms-menu-dialog',
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    MatCard,
  ],
  templateUrl: './menu-dialog.component.html',
  styleUrls: ['./menu-dialog.component.scss'],
})
export class MenuDialogComponent {
  constructor(public dialogRef: MatDialogRef<MenuDialogComponent>) {}

  close(): void {
    this.dialogRef.close();


  }
}



