import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    loadComponent: () =>
      import('./home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'philosophy',
    loadComponent: () =>
      import('./philosophy/philosophy.component').then(
        (m) => m.PhilosophyComponent
      ),
  },
  {
    path: 'colors',
    loadComponent: () =>
      import('./resonance-colors/resonance-colors.component').then(
        (m) => m.ResonanceColorsComponent
      ),
  },
  {
    path: 'gallery',
    loadComponent: () =>
      import('./gallery/gallery.component').then((m) => m.GalleryComponent),
  },
  {
    path: 'artist',
    loadComponent: () =>
      import('./artist-showcase/artist-showcase.component').then(
        (m) => m.ArtistShowcaseComponent
      ),
  },
  {
    path: 'courses',
    loadComponent: () =>
      import('./courses/courses.component').then((m) => m.CoursesComponent),
  },
  {
    path: 'contact',
    loadComponent: () =>
      import('./contact/contact.component').then((m) => m.ContactComponent),
  },
  {
    path: 'admin',
    loadComponent: () =>
      import('./admin/admin.component').then((m) => m.AdminComponent),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./login/login.component').then((m) => m.LoginComponent),
  },
  { path: '**', redirectTo: '/home' },
];
